.qa-container {
    margin: 10px;
}

.question {
    cursor: pointer;
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.answer {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
}