@import "~antd/dist/antd.css";

.agora-wrapper .title-box {
  width: 100%;
  height: 65px;
  background-color: #50d8fa;
  margin-bottom: 20px;
}

.agora-wrapper #title-agora {
  /* font-size: 25px; */
  /* color: white;
  font-family: "HelveticaNeue"; */
  display: flex;
  justify-content: center;

  /* transform: translateY(40%); */
}

.agora-wrapper .logo {
  padding-top: 8px;
  width: 150px;
}

.agora-wrapper .aperture {
  /* display: inline-block; */
  width: 58px !important;
  height: 58px !important;
  position: absolute;
  right: 100px;
  top: 41px;
  z-index: 1999;
}

.agora-wrapper .aperture::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -24px;
  left: 16px;
  border-radius: 50%;
  box-shadow: inset 0 0 10px #fff06a, inset 4px 0 16px #f0f,
    inset -4px 0 16px #0ff, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff,
    0 0 10px #fff06a, -6px 0 36px #f0f, 6px 0 36px #0ff;
  -webkit-animation: rotate 3s infinite linear;
  animation: rotate 3s infinite linear;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.agora-wrapper .message-box {
  margin-left: 30px;
  display: none;
}

.agora-wrapper .text-agora {
  font-weight: bold;
  font-size: 14px;
  /* margin-left: 10px; */
}

.agora-wrapper .input-box {
  width: calc(100% - 40px);
  margin-top: 5px;
  margin-bottom: 15px;
}

.agora-wrapper .click-box {
  display: flex;
  margin-top: 20px;
}

.agora-wrapper .click-box button:first-child {
  margin-right: 30px;
}

.agora-wrapper .click-box button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.joinButton {
  /* margin-right: 30px; */
}

.leaveButton {
  /* margin-left: 10px;
  font-family: "Poppins", sans-serif; */
}

.agora-wrapper .video-agora-box {
  display: flex;
  flex-wrap: wrap-reverse;
  /* margin: 20px; */
  margin-left: 30px;
  margin-right: 30px;
  height: calc(100vh - 200px);
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.video-agora-box > div:not(#video-agora-local) {
  width: calc(50% - 50px) !important;
  height: 90% !important;
}

.video-agora-box.stretched > div:not(#video-agora-local) {
  width: calc(70% - 50px) !important;
  height: 90% !important;
}

.agora-wrapper #video-agora-local {
  /* width: 320px;
  height: 240px;
  margin-right: 75px; */
  height: 90% !important;
  width: calc(50% - 50px) !important;
}

.video-agora-box.stretched > div#video-agora-local {
  width: calc(30% - 50px) !important;
  height: 90% !important;
}

.agora-wrapper .buttons {
  margin: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.agora_video_player {
  object-fit: contain !important;
}

/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/* General Buttons */
.agora-wrapper button {
  width: 130px;
  height: 40px;
  background: linear-gradient(to bottom, #50d8fa 0%, #44bad8 100%); /* W3C */
  border: none;
  border-radius: 5px;
  position: relative;
  border-bottom: 4px solid #2b8bc6;
  color: #fbfbfb;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  font-size: 15px;
  text-align: left;
  text-indent: 5px;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  /* Just for presentation */
  display: block;
  margin: 0 auto;
  /* margin-bottom: 20px;
  margin-right: 20px; */
}
.agora-wrapper button:active {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
  top: 1px;
}

.agora-wrapper button:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-top: 20px solid #187dbc;
  border-bottom: 20px solid #187dbc;
  border-left: 16px solid transparent;
  border-right: 20px solid #187dbc;
  position: absolute;
  opacity: 0.6;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
}

/* Button pointing left */

.agora-wrapper button.back {
  text-align: right;
  padding-right: 12px;
  box-sizing: border-box;
  margin-left: 20px;
}
.agora-wrapper button.back:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-top: 20px solid #187dbc;
  border-bottom: 20px solid #187dbc;
  border-right: 16px solid transparent;
  border-left: 20px solid #187dbc;
  position: absolute;
  opacity: 0.6;
  left: 0;
  top: 0;
  border-radius: 5px 0 0 5px;
}

/* Single buttons */

.agora-wrapper button.site {
  width: 40px;
  text-align: center;
  text-indent: 0;
}
.agora-wrapper button.site:after {
  display: none;
}

/* UI Footer */

.page-footer .page-footer-inner {
  color: #98a6ba;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 300px;
  margin: 0 auto;
}
.page-footer .page-footer-inner svg {
  cursor: pointer;
}
.page-footer-fixed .page-footer {
  background-color: #28303b;
}
.page-footer-fixed.page-footer-fixed-mobile .page-container {
  margin-bottom: 20px !important;
}
.page-footer {
  padding: 36px 5px;
  font-size: 13px;
  background-color: transparent;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  text-align: center;
}
.page-footer:after,
.page-footer:before {
  content: " ";
  display: table;
}

.page-footer-fixed.page-footer-fixed-mobile .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}
.page-footer-fixed.page-footer-fixed-mobile.sidemenu-container-fixed
  .page-footer {
  margin-left: 0 !important;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.hrbottom {
  width: 96%;
  padding-bottom: 10px;
}

/* Content */

@media all and (max-width: 768px) {
  .video-agora-box > div:not(#video-agora-local),
  .agora-wrapper #video-agora-local {
    width: 100% !important;
    height: 50% !important;
    padding-bottom: 10px;
  }
  .video-agora-box.stretched > div:not(#video-agora-local) {
    width: 100% !important;
    height: 70% !important;
  }
  .video-agora-box.stretched > div#video-agora-local {
    width: 100% !important;
    height: 30% !important;
  }
}

.expanded {
  width: 100%;
  height: 100%;
}

.colored {
  background-color: red;
  height: 100%;
  width: 100%;
}
.colored2 {
  background-color: blue;
  height: 100%;
  width: 100%;
}
/* .example {
  display: flex;
  min-height: 20vh;
  flex-wrap: wrap;
}
.example div {
  flex: 1;
  min-width: 300px; */
/* 2 children + margin and borders makes a break point at around 620px */
/* background: lightblue;
}
@media screen and (max-width: 621px) {
  .example {
    min-height: 30vh; */
/* has a meaning with a grid system */
/* }
} */
