.schedule-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-row p {
  margin-bottom: 0;
  width: 200px;
}

.schedule-row a {
  margin-left: 20px;
}

.schedule-time-wrapper {
}
.MuiFormControl-root {
  width: 100%;
}
