table td,th{
    min-width: 150px;
    &.text-right{
        min-width: auto;
    }
}

.rdtPicker {
    width: 100%;
}

.rdtPicker table td,.rdtPicker table th{
    min-width: auto;
    width:auto;
}

table.dataTable thead > tr > th {
    padding-left: 30px !important;
    padding-right: initial !important;
}

table.dataTable thead .sorting_asc {
    background: url("http://cdn.datatables.net/1.10.0/images/sort_asc.png") no-repeat center left;
  }
  table.dataTable thead .sorting_desc {
    background: url("http://cdn.datatables.net/1.10.0/images/sort_desc.png") no-repeat center left;
  }
  table.dataTable thead .sorting {
    background: url("http://cdn.datatables.net/1.10.0/images/sort_both.png") no-repeat center left;
  }

  .react-tel-input .form-control {
      width: 100% !important;
  }

  .MuiMenu-paper{
    height: 25% !important;
  }