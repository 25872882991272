.registration-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.registration-form .form-group {
    margin-bottom: 15px;
}

.registration-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.registration-form input,
.registration-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
}

.registration-form .error {
    color: #ff0000;
    font-size: 14px;
}

.registration-form button {
    width: 100%;
    padding: 10px;
    background-color: #50d8fa;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.registration-form button:hover {
    background-color: #00b8d4;
}