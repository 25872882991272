.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}

.product-card {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}