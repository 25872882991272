button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.on {
    color: #febd00;
}

.off {
    color: #ccc;
}