html,
body,
body > div {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

/* li {
    margin: 1.5em 0;
    padding: 0;
} */

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.full-calendar {
  /* display: flex; */
  /* min-height: 100%; */
  /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
  /* font-size: 14px; */
}

.full-calendar-sidebar {
  width: 200px;
  line-height: 1.5;
  background: white;
  border-right: 0px solid #d3e2e8;
}

.full-calendar-sidebar-section {
  padding: 1em;
}

.full-calendar-main {
  flex-grow: 1;
  padding: 3em;
}

.fc-col-header {
  height: 61px;
}

.fc-scrollgrid-sync-inner {
  font-size: 15px;
}

.fc-scrollgrid-sync-inner {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: sans-serif;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

/* custom */
.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-event-title {
  color: white;
}

.fc-event-title-New {
  text-align: left;
}

.fc-timegrid-event-harness {
  min-height: 65px;
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  /* background-color: #349ed3 !important; */
}

.fc-event,
.fc-event-dot {
  /* background-color: #1d3041 !important; */
}

.fc-popover {
  top: 200px;
  left: 440px;
}

.fc-button-primary {
  background-color: white !important;
  color: black !important;
}

.fc-prev-button {
  background-color: white !important;
  color: black !important;
}

.fc .fc-button-primary {
  background-color: white !important;
  color: black !important;
  border: none !important;
}
.fc-header-toolbar {
  background-color: rgb(247, 247, 248);
  padding-top: 5px;
  padding-bottom: 5px;
}

.fc-today-button {
  color: black !important;
}

.fc .fc-timegrid-slot-label {
  /* height: 1.5em; */
  /* border-bottom: none !important; */
  /* border: none !important; */
}

.fc .fc-timegrid-slot {
  height: 65px !important;

}

.fc-timegrid-event-harness > .fc-timegrid-event {
  border-radius: 5px;
}

.fc-col-header-cell-cushion {
  color: black !important;
  font-weight: 500;
  font-family: sans-serif;
  font-size: 20px;
}

.cardMargins {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.sideTime {
  font-weight: 600;
  color: black;
  margin-top: -15px;
}

.dayHeader {
  font-size: 14px;
  margin-top: 1px;
  margin-left: 5px;
  align-self: center;
}

.dayHeaderContentDiv {
  display: flex;
  text-align: left !important;
  margin-left: 5px;
}

.fc-col-header-cell-cushion {
  text-align: left !important;
  display: flex !important;
}

.dateHeader {
  font-size: 27px;
}

.isTodayDateHeader {
  font-size: 27px;
  background-color: #037aff;
  color: #fff;
  margin-left: 5px;
  border-radius: 50%;
  padding:0px 5px;
  width: 40px;
  height: 40px;
}

.isTodayDayHeader {
  font-size: 14px;
  margin-top: 1px;

  margin-left: 5px;
  align-self: center;
  color: #037aff;
}

.fc th {
  border-left: none !important;
  border-right: none !important;
  
  
}

.fc th:nth-child(1) {
  border-bottom:white !important
 
}

.fc-scroller {
  box-shadow: 10px 10px 5px 10px rgb(0 0 0 / 0.2) !important;
}



.fc .fc-timegrid-slot:nth-child(1) {
  border: none !important;
  
}


.fc .fc-scrollgrid {
  border: none !important;
  box-shadow: 0px 0px 14px rgb(0 0 0 / 20%) !important
}

.fc-scrollgrid-section-header {
      box-shadow: 0px 0px 14px rgb(0 0 0 / 20%) !important
}

/* .fc-col-header-cell  {
  box-shadow: 0px 5px 8px 10px rgb(0 0 0 / 20%) !important
} */

.sideMeridian {
  position: relative;
  margin-top: -7px;
}

